<template>
  <div class="mt-1">
    <week-list-skeleton v-if="isLoading" />
    <div v-else>
      <div class="w-100 bg-white mb-1 h-auto">
        <span
          v-if="getWarehouseOrdersList.unresolved.length"
          class="d-block w-100 text-center font-medium-5 font-weight-bolder"
        >{{ 'Unresolved' }}</span>
        <div
          v-for="weekDay in Object.keys(getWarehouseOrdersList.unresolved)"
          :key="weekDay"
          class="w-100"
        >
          <div
            class="w-100 mb-1 h-auto"
            :style="`background-color: ${weekDay === getToday ? '#00B13926' : '#fff'}`"
          >
            <span class=" d-block w-100 text-center font-medium-5 font-weight-bolder mb-1">
              {{ weekDay }}
            </span>
            <span
              v-for="hour in Object.keys(getWarehouseOrdersList.orders[weekDay])"
              :key="hour"
              class="d-flex align-items-end flex-row mb-1"
            >
              <span
                class="pl-1 font-weight-bolder"
                style="width: 100px"
              >{{ hour }}</span>
              <span
                v-for="order in getWarehouseOrdersList.orders[weekDay][hour]"
                :key="order._order_id"
                class="text-white"
                :style="`height: 30px; width: 92%; background-color: ${ order.status && orderStatesAndStatuses[order.status].style.color}`"
                @click="handleOrderClick(order)"
              >
                <feather-icon
                  icon="LWarehouseIcon"
                  size="16"
                  style="margin: 8px"
                  class="text-white stroke-current"
                />
                {{ order._order_id }}
              </span>
            </span>
          </div>

        </div>
      </div>
      <div
        v-for="weekDay in Object.keys(getWarehouseOrdersList.orders)"
        :key="weekDay"
        class="w-100"
      >
        <div
          class="w-100 mb-1 h-auto"
          :style="`background-color: ${weekDay === getToday ? '#00B13926' : '#fff'}`"
        >
          <span class=" d-block w-100 text-center font-medium-5 font-weight-bolder mb-1">
            {{ weekDay }}
          </span>
          <span
            v-for="hour in Object.keys(getWarehouseOrdersList.orders[weekDay])"
            :key="hour"
            class="d-flex align-items-end flex-row mb-1"
          >
            <span
              class="pl-1 font-weight-bolder"
              style="width: 100px"
            >{{ hour }}</span>
            <span
              v-for="order in getWarehouseOrdersList.orders[weekDay][hour]"
              :key="order._order_id"
              class="text-white"
              :style="`height: 30px; width: 92%; background-color: ${order.status && orderStatesAndStatuses[order.status].style.color}`"
              @click="handleOrderClick(order)"
            >
              <feather-icon
                icon="LWarehouseIcon"
                size="16"
                style="margin: 8px"
                class="text-white stroke-current"
              />
              {{ order._order_id }}
            </span>
          </span>
        </div>

      </div>
    </div>
    <portal to="body-footer">
      <div>
        <manage-weekly-date :params="{sorting: 'list'}" />
      </div>
    </portal>
  </div>
</template>
<script>
import ManageWeeklyDate from '@/views/main/warehouse/main-order/components/use-as-global/ManageWeeklyDate.vue'
import WeekListSkeleton from '@/views/main/warehouse/main-order/components/skeletons/WeekListSkeleton.vue'
import config from '../../config'

export default {
  name: 'WeekListByOrders',
  components: { WeekListSkeleton, ManageWeeklyDate },
  computed: {
    getWarehouseOrdersList() {
      return this.$store.state[this.MODULE_NAME].warehouseOrdersList
    },
    isLoading() {
      return this.$store.state[this.MODULE_NAME].isLoading
    },
    getToday() {
      const day = new Date().toLocaleDateString('en-us', { day: 'numeric' })
      const weekDay = new Date().toLocaleDateString('en-us', { weekday: 'short' })
      return `${weekDay} ${day}`
    },
  },
  methods: {
    handleOrderClick(order) {
      if (!order.order_type) return

      this.handleOrderStatus(this, order)
    },
  },
  setup() {
    const { MODULE_NAME, orderStatesAndStatuses, handleOrderStatus } = config()

    return {
      MODULE_NAME,
      orderStatesAndStatuses,
      handleOrderStatus,
    }
  },
}
</script>
